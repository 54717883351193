import { useNavigate, useParams } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { OwnerProfileForm } from './OwnerProfileForm'
import { useOwnerProfileValidation } from './utils/owner-validation-hooks'

export const OwnershipEditOwner = () => {
  const params = useParams()

  const ownerId = params.ownerId
  if (!ownerId) throw new Error('OwnerId not found')

  const t = useOnboardingTranslations()
  const navigate = useNavigate()

  const heading = t('ownership.profile.coOwnerHeading')

  const { hasError, showErrors, setShowErrors } = useOwnerProfileValidation({ ownerId })

  const onContinue = () => {
    setShowErrors(true)
    if (hasError) return
    navigate(Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS)
  }
  const onBack = () => {
    navigate(Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS)
  }

  const primaryButton = <ContinueButton testID="owner-profiles-primary-owner-continue" onPress={onContinue} fullWidth />
  const secondaryButton = <GoBackButton testID="owner-profiles-primary-owner-back" onPress={onBack} fullWidth />

  return (
    <BaseContentLayout primaryButton={primaryButton} secondaryButton={secondaryButton} headingText={heading}>
      <OwnerProfileForm ownerId={ownerId} shouldShowValidationErrors={showErrors} />
    </BaseContentLayout>
  )
}
