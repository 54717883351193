import { analytics as segmentAnalytics } from '@northone/segment-js'
import {
  AlertInformationIcon,
  AlertTypeEnum,
  Body,
  BodySizeEnum,
  Box,
  ButtonGroup,
  CardText,
  CardTextSizeEnum,
  Checkbox,
  DateInput,
  DropdownSmall,
  InlineAlert,
  Link,
  PageHeader,
  PageHeaderSizeEnum,
  PasswordInput,
  PhoneNumberInput,
  PrimaryButton,
  PrimaryButtonProps,
  SecondaryButton,
  SecondaryButtonProps,
  SizeEnum,
  TextInput,
  Tooltip,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import emailMisspelled, { top100 } from 'email-misspelled'
import { DateTime } from 'luxon'
import { ComponentProps, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import AddressAutocompleteInput from '@/components/AddressAutocompleteInput'
import LoadingScreen from '@/components/LoadingScreen'
import WhyDoWeCollectThisInfo from '@/components/WhyDoWeCollectThisInfo'
import { analytics } from '@/core/analytics/events'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { getBusinessAddress } from '@/core/redux/application-redux/application-selectors'
import {
  CONTROL_PERSON_CANDIDATE_ID,
  IAddressState,
  IOwner,
  PRIMARY_OWNER_ID,
} from '@/core/redux/application-redux/application-state'
import { selectOtherOwnerDataByID } from '@/core/redux/application-redux/owners-selectors'
import { unpersistedActions } from '@/core/redux/unpersisted-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import useBusinessType from '@/hooks/useBusinessType'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { americanStates, cleanPercentage, DATE_INPUT_FORMAT, removeAllNonNumbers } from '@/utils'

import { OwnerProfileInputIDs } from './constants'
import { useOwnerProfileValidation } from './utils/owner-validation-hooks'

type TextInputProps = ComponentProps<typeof TextInput>
type DateInputProps = ComponentProps<typeof DateInput>

export interface OwnerProfileFormProps {
  isEditing?: boolean
  setIsEditing?: (isEditing: boolean) => void
  ownerId: string
  isControllerForm?: boolean
  shouldShowValidationErrors?: boolean
  onEmailSuggestionAccepted?: (email: string) => void
  onEmailSuggestionDismiss?: () => void
  emailSuggestion?: string
}

export const OwnerProfileForm = ({
  setIsEditing,
  ownerId,
  isControllerForm = false,
  shouldShowValidationErrors,
  onEmailSuggestionAccepted,
  onEmailSuggestionDismiss = () => undefined,
  emailSuggestion,
}: OwnerProfileFormProps) => {
  const t = useOnboardingTranslations()
  const { isMobileSize } = useMobileScreenSize()
  const { isSoleProp } = useBusinessType()
  const ownerIsPrimaryOwner = ownerId === PRIMARY_OWNER_ID
  const isFinancialAdminInvitingEnabled = useFeatureFlag('financial-admin-invites-enabled')
  const areSeparateOwnerProfileFormsEnabled = useFeatureFlag('joindot-dedicated-owner-profile-forms-enabled')

  const dispatch = useDispatch()
  const owner = useAppSelector(selectOtherOwnerDataByID(ownerId))
  const ownerSSN = useAppSelector((state) => state.unpersisted.ssns)[ownerId]
  const businessName = useAppSelector((state) => state.application.businessName)
  const hasSavedBefore = owner?.hasSavedBefore
  const hasEnteredSSNBefore = owner?.hasEnteredSSNBefore
  const isPrimaryOwner = ownerId === PRIMARY_OWNER_ID
  const [hasClickedEnterAddressManually, setHasClickedEnterAddressManually] = useState(false)

  const emailChecker = emailMisspelled({ maxMisspelled: 1, domains: top100 })
  const [emailSuggestions, setEmailSuggestions] = useState<string[]>([])
  const [shouldDisableDoneButton, setShouldDisableDoneButton] = useState(false)
  const [wasAlertDismissed, setWasAlertDismissed] = useState(false)

  const addressAutocompleteLabel = t('ownership.profile.labels.addressAutocomplete')
  const addressAutocompletePlaceholder = t('ownership.profile.placeholders.addressAutocomplete')
  const addressAutocompleteHelperText = t('inputs.addressAutocompleteHelperText')
  const enterAddressManuallyText = t('inputs.addressAutocompleteEnterAddressManually')
  const whyDoWeCollectThisInformation = t('tooltip.whyDoWeCollectThisInformation')
  const whyDoWeCollectContactInfo = t('ownership.profile.helperText.whyDoWeCollectContactInfo')
  const whyDoWeCollectSSN = t('ownership.profile.helperText.whyDoWeCollectSSN')
  const whyDoWeCollectSSNPrimaryOwner = t('ownership.profile.helperText.whyDoWeCollectSSNPrimaryOwner')
  const whyDoWeCollectSSNOther = t('ownership.profile.helperText.whyDoWeCollectSSNOther', {
    userType: isControllerForm ? 'financial administrator’s' : "co-owners'",
  })

  const sameBusinessAndHomeAddressCheck = useAppSelector(
    (state) => state.application.selectedSameBusinessAndHomeAddressCheckbox,
  )

  const onPressEnterManually = () => {
    setHasClickedEnterAddressManually(true)
  }
  const shouldShowAddressFields =
    hasClickedEnterAddressManually ||
    sameBusinessAndHomeAddressCheck ||
    owner.address.streetAddress ||
    owner.address.suite ||
    owner.address.city ||
    owner.address.state ||
    owner.address.zipCode

  const formHeading =
    isPrimaryOwner || areSeparateOwnerProfileFormsEnabled
      ? undefined
      : isControllerForm
      ? t('applicationForm.ownerProfiles.controlPerson.otherCandidate.formTitle')
      : t('ownership.profile.coOwnerHeading')

  const updateOwner = (updatedOwnerFields: Partial<IOwner>) =>
    dispatch(applicationActions.updateOwner({ updatedOwnerFields, ownerId }))

  const firstName = owner?.firstName
  const setFirstName = (firstName: string) => updateOwner({ firstName })

  const lastName = owner?.lastName
  const setLastName = (lastName: string) => updateOwner({ lastName })

  const jobTitle = owner?.jobTitle
  const setJobTitle = (jobTitle: string) => updateOwner({ jobTitle })

  const birthdate = owner?.birthdate
  const dateTimeBirthdate = DateTime.fromFormat(birthdate ?? '', DATE_INPUT_FORMAT)
  const setBirthdate = (birthdate?: DateTime) => {
    updateOwner({ birthdate: birthdate?.toFormat(DATE_INPUT_FORMAT) })
  }

  const email = owner?.email ?? ''
  const setEmail = (email: string) => {
    updateOwner({ email })
    setEmailSuggestions([])
  }

  const onCloseEmailSuggestions = () => {
    setEmailSuggestions([])
    setWasAlertDismissed(true)
    setShouldDisableDoneButton(false)
    onEmailSuggestionDismiss()
  }

  const onSelectSuggestion = (suggestion: string) => {
    setEmail(suggestion)
    setShouldDisableDoneButton(false)
    onEmailSuggestionAccepted?.(suggestion)
  }

  // useEffect for handling email suggestions based on the prop
  useEffect(() => {
    if (emailSuggestion) {
      setEmailSuggestions([emailSuggestion])
    }
  }, [emailSuggestion])

  const phoneNumber = owner?.phoneNumber
  const setPhoneNumber = (phoneNumber: string) => updateOwner({ phoneNumber })

  const SSN = ownerSSN
  const setSSN = (ssn: string) => {
    const cleanedSSN = removeAllNonNumbers(ssn)
    dispatch(unpersistedActions.setSSN({ ssn: cleanedSSN, ownerId }))
  }

  const ownerAddress = owner.address
  const streetAddress = ownerAddress.streetAddress
  const suite = ownerAddress.suite
  const city = ownerAddress.city
  const americanState = ownerAddress.state
  const zipCode = ownerAddress.zipCode

  const setStreetAddress = (streetAddress: string) => updateOwner({ address: { ...ownerAddress, streetAddress } })
  const setZip = (zipCode: string) => updateOwner({ address: { ...ownerAddress, zipCode } })
  const setSuiteNumber = (suite: string) => updateOwner({ address: { ...ownerAddress, suite } })
  const setAmericanState = (state: string) => updateOwner({ address: { ...ownerAddress, state } })
  const setCity = (city: string) => updateOwner({ address: { ...ownerAddress, city } })

  const businessAddress = useAppSelector(getBusinessAddress)

  useEffect(() => {
    if (sameBusinessAndHomeAddressCheck === true && isPrimaryOwner) {
      dispatch(
        applicationActions.updateOwner({
          updatedOwnerFields: {
            address: {
              streetAddress: businessAddress.streetAddress,
              zipCode: businessAddress.zipCode,
              city: businessAddress.city,
              state: businessAddress.state,
              suite: businessAddress.suite,
              description: businessAddress.description,
              coordinates: businessAddress.coordinates,
            },
          },
          ownerId,
        }),
      )
    }
  }, [])

  const ownershipPercentage = owner?.ownershipPercentage
  const setOwnershipPercentage = (ownershipPercentage: string) => {
    const cleanedPercentage = cleanPercentage(ownershipPercentage)
    updateOwner({ ownershipPercentage: cleanedPercentage })
  }

  const shouldSendInvite = Boolean(owner?.isInviteCheckboxSelected && !ownerIsPrimaryOwner)
  const shouldShowCheckbox =
    !ownerIsPrimaryOwner && !isSoleProp && Boolean(isControllerForm ? isFinancialAdminInvitingEnabled : true)
  const handleCheckboxChange = () => updateOwner({ isInviteCheckboxSelected: !shouldSendInvite })

  const inviteCheckboxLabel = isControllerForm
    ? t('ownership.profile.labels.financialAdminCheckboxInvite')
    : t('ownership.profile.labels.coownerCheckboxInvite')

  const { hasError, showErrors, setShowErrors, fieldErrors, scrollToFirstInputWithError } = useOwnerProfileValidation({
    ownerId,
  })

  const getSSNErrorText = () => {
    const shouldDisplayErrorToReEnterSSNForSoleProp = hasSavedBefore && hasEnteredSSNBefore && !SSN && isSoleProp
    if (shouldDisplayErrorToReEnterSSNForSoleProp) return t('applicationForm.ownerProfiles.ssnError')
    return fieldErrors.ssn ?? ''
  }

  useEffect(() => {
    if (showErrors && !hasError) {
      setShowErrors(false)
    }
    if (!shouldShowValidationErrors) return
    setShowErrors(true)
  }, [hasError, showErrors, setShowErrors, shouldShowValidationErrors])

  const userId = useAppSelector((state) => state.application.userId)

  const isCorpWithMultipleOwners = useAppSelector((state) => state.application.isCorpWithMultipleOwners)

  if (!owner) return null

  const onCancel = () => {
    if (isPrimaryOwner) {
      setIsEditing?.(false)
      return
    }

    // Co-owners: If previously filled out, cancel editing. If never been filled out, delete co-owner
    hasSavedBefore ? setIsEditing?.(false) : dispatch(applicationActions.deleteOwner(ownerId))
  }

  const isDisabled = showErrors || shouldDisableDoneButton

  /**
   *
   * @returns isValidSubmission: boolean
   */
  const onSubmit = () => {
    setShowErrors(true)
    if (hasError) {
      scrollToFirstInputWithError()
      return false
    }
    // Check for possible email typos
    const suggestions = emailChecker(email).map((result) => result.corrected)
    if (suggestions.length > 0 && !wasAlertDismissed) {
      // Ensures we only care about the first suggestion
      if (suggestions[0]) {
        setEmailSuggestions([suggestions[0]])
      }
      setShouldDisableDoneButton(true)
      return false
    }
    updateOwner({
      hasSavedBefore: true,
      address: {
        ...ownerAddress,
        description: '',
        coordinates: {
          longitude: 0,
          latitude: 0,
        },
      },
    })
    if (!owner.hasEnteredSSNBefore) {
      dispatch(updateOwner({ hasEnteredSSNBefore: true }))
    }
    if (isPrimaryOwner) {
      segmentAnalytics().identify(userId, { firstName, lastName })
      analytics.application.fillOwnerFirstName({ name: firstName })
      analytics.application.fillOwnerLastName({ name: lastName })
      analytics.application.fillOwnerJobTitle({ title: jobTitle })
      analytics.application.fillOwnerDateOfBirth({ dateOfBirth: birthdate })
      analytics.application.fillOwnerSSN()
      analytics.application.fillOwnerHomeAddress({ address: ownerAddress })
      ownershipPercentage && analytics.application.fillOwnerPercentageOwnership({ percentage: ownershipPercentage })
    }
    if (isSoleProp) {
      analytics.application.ownerProfileSave({ multipleOwners: false })
    } else {
      ownerId === CONTROL_PERSON_CANDIDATE_ID
        ? analytics.application.controlPersonSave()
        : isPrimaryOwner
        ? analytics.application.ownerProfileSave({ multipleOwners: isCorpWithMultipleOwners })
        : analytics.application.coOwnerSave()
    }
    setIsEditing?.(false)
    return true
  }
  const doneButtonProps: PrimaryButtonProps = {
    children: t('button.done'),
    onPress: onSubmit,
    disabled: isDisabled,
    testID: `owner-profile-form-${ownerId}.primary-button`,
    fullWidth: true,
  }

  const shouldHideCancelButton = ownerIsPrimaryOwner && !isSoleProp
  const cancelButtonProps: SecondaryButtonProps | undefined = shouldHideCancelButton
    ? undefined
    : {
        children: t('button.cancel'),
        onPress: onCancel,
        testID: '`owner-profile-form-${ownerId}.secondary-button`',
        fullWidth: true,
      }

  const firstNameInputProps: TextInputProps = {
    testID: 'first-name',
    inputID: OwnerProfileInputIDs.firstName,
    onChange: setFirstName,
    value: firstName ?? '',
    labelText: t('ownership.profile.labels.firstName'),
    errorText: fieldErrors.firstName,
  }
  const lastNameInputProps: TextInputProps = {
    testID: 'last-name',
    inputID: OwnerProfileInputIDs.lastName,
    onChange: setLastName,
    value: lastName ?? '',
    labelText: t('ownership.profile.labels.lastName'),
    errorText: fieldErrors.lastName,
  }
  const jobTitleInputProps: TextInputProps = {
    testID: 'job-title',
    inputID: OwnerProfileInputIDs.jobTitle,
    onChange: setJobTitle,
    value: jobTitle ?? '',
    labelText: t('ownership.profile.labels.jobTitle'),
    errorText: fieldErrors.jobTitle,
  }
  const birthdateInputProps: DateInputProps = {
    testID: 'birthdate',
    inputID: OwnerProfileInputIDs.birthdate,
    onChange: setBirthdate,
    value: dateTimeBirthdate,
    labelText: t('ownership.profile.labels.dateOfBirth'),
    errorText: fieldErrors.birthdate,
  }
  const emailInputProps: TextInputProps | undefined = isPrimaryOwner
    ? undefined
    : {
        testID: 'email',
        inputID: OwnerProfileInputIDs.email,
        onChange: setEmail,
        value: email,
        labelText: t('ownership.profile.labels.email'),
        errorText: fieldErrors.email,
      }
  const phoneNumberInputProps: TextInputProps = {
    testID: 'phone',
    inputID: OwnerProfileInputIDs.phone,
    onChange: setPhoneNumber,
    value: phoneNumber ?? '',
    labelText: t('ownership.profile.labels.phoneNumber'),
    errorText: fieldErrors.phone,
  }
  const ssnInputProps: TextInputProps = {
    testID: 'ssn',
    inputID: OwnerProfileInputIDs.ssn,
    onChange: setSSN,
    value: SSN ?? '',
    labelText: t('ownership.profile.labels.ssnLong'),
    helperText: isMobileSize ? t('ownership.profile.helperText.ssn') : '',
    errorText: getSSNErrorText(),
  }
  const streetAddressInputProps: TextInputProps = {
    testID: 'street-address',
    inputID: OwnerProfileInputIDs.streetAddress,
    onChange: setStreetAddress,
    value: streetAddress ?? '',
    labelText: t('ownership.profile.labels.streetAddress'),
    helperText: t('ownership.profile.helperText.streetAddress'),
    errorText: fieldErrors.streetAddress,
  }
  const suiteInputProps: TextInputProps = {
    testID: 'suite',
    inputID: OwnerProfileInputIDs.suite,
    onChange: setSuiteNumber,
    value: suite ?? '',
    labelText: t('ownership.profile.labels.suite'),
    errorText: fieldErrors.suite,
  }

  const cityInputProps: TextInputProps = {
    testID: 'city',
    inputID: OwnerProfileInputIDs.city,
    onChange: setCity,
    value: city ?? '',
    labelText: t('ownership.profile.labels.city'),
    errorText: fieldErrors.city,
  }
  const americanStateInputProps: ComponentProps<typeof DropdownSmall> = {
    onChange: (val: string) => setAmericanState(val.toString()),
    value: americanState,
    options: americanStates,
    labelText: t('ownership.profile.labels.state'),
    errorText: fieldErrors.state,
    placeholderProps: {
      text: t('ownership.profile.labels.state'),
    },
  }
  const zipInputProps: TextInputProps = {
    testID: 'zip',
    inputID: OwnerProfileInputIDs.zip,
    onChange: setZip,
    value: zipCode ?? '',
    labelText: t('ownership.profile.labels.zip'),
    errorText: fieldErrors.zip,
  }
  const ownershipPercentageInputProps: TextInputProps = {
    testID: 'ownership-percentage',
    inputID: OwnerProfileInputIDs.ownershipPercentage,
    onChange: setOwnershipPercentage,
    value: ownershipPercentage ?? '',
    labelText: t('ownership.profile.labels.ownershipPercentage', { businessName }),
    errorText: fieldErrors.ownershipPercentage,
  }

  const onAddressSelected = (autoCompleteAddress: IAddressState) => {
    updateOwner({ address: autoCompleteAddress })
  }

  const shouldShowOwnershipPercentageField = !isSoleProp && !isControllerForm
  const shouldShowCancelAndDoneButtons = !isSoleProp && !isControllerForm

  if (isFinancialAdminInvitingEnabled === undefined || areSeparateOwnerProfileFormsEnabled === undefined)
    return <LoadingScreen />

  const TooltipWhyDoWeCollect = () => (
    <Tooltip content={whyDoWeCollectContactInfo} title={whyDoWeCollectThisInformation}>
      <WhyDoWeCollectThisInfo />
    </Tooltip>
  )

  const OwnerSSNTooltip = () => {
    const { isMobileSize } = useMobileScreenSize()
    return (
      <Tooltip
        content={isPrimaryOwner ? whyDoWeCollectSSNPrimaryOwner : whyDoWeCollectSSNOther}
        title={whyDoWeCollectSSN}
        alignment={isMobileSize ? 'flex-start' : 'flex-end'}
        position="top"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '$6',
            justifyContent: 'space-between',
          }}
        >
          {!isMobileSize && <Body size={BodySizeEnum.XS}>{t('ownership.profile.helperText.ssn')}</Body>}
          <Box
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 2,
            }}
          >
            <Body size={BodySizeEnum.XS}>{whyDoWeCollectSSN}</Body>
            <AlertInformationIcon color={'$charcoal5'} size={SizeEnum.SM} />
          </Box>
        </Box>
      </Tooltip>
    )
  }

  return (
    <Box sx={{ gap: '$8' }}>
      {isControllerForm && formHeading && (
        <Box sx={{ gap: '$4', zIndex: 3 }}>
          <PageHeader size={PageHeaderSizeEnum.SM} headingLevel={1}>
            {formHeading}
          </PageHeader>
          <TooltipWhyDoWeCollect />
        </Box>
      )}
      <Box sx={{ gap: '$4', zIndex: 2 }}>
        <Box sx={{ flexDirection: 'row', gap: '$4', flexWrap: 'wrap' }}>
          <Box sx={{ flex: 1, minWidth: 180 }}>
            <TextInput {...firstNameInputProps} />
          </Box>
          <Box sx={{ flex: 1, minWidth: 180 }}>
            <TextInput {...lastNameInputProps} />
          </Box>
        </Box>
        {jobTitleInputProps ? <TextInput {...jobTitleInputProps} /> : null}
        <Box sx={{ zIndex: 3 }}>
          <DateInput {...birthdateInputProps} />
        </Box>
        {emailInputProps ? <TextInput {...emailInputProps} /> : null}
        {emailSuggestions.length > 0 && (
          <InlineAlert showIcon type={AlertTypeEnum.Warning} onClose={onCloseEmailSuggestions}>
            <Box>
              <CardText size={CardTextSizeEnum.MD} color={'$warning4'}>
                {t('ownership.profile.emailSuggestion')}
                {emailSuggestions.map((suggestion) => (
                  <Link key={suggestion} onPress={() => onSelectSuggestion(suggestion)}>
                    {suggestion}
                  </Link>
                ))}
                ?
              </CardText>
            </Box>
          </InlineAlert>
        )}
        <PhoneNumberInput {...phoneNumberInputProps} />
        <Box sx={{ zIndex: 1 }}>
          <PasswordInput {...ssnInputProps} />
          <OwnerSSNTooltip />
        </Box>

        {/* Adding padding rather than using a container with a gap because containers cause conflicting z-index contexts
        with the address autocomplete field and the birthdate field */}
        <Box sx={{ zIndex: 2, paddingTop: '$2' }}>
          <AddressAutocompleteInput
            label={addressAutocompleteLabel}
            helperText={addressAutocompleteHelperText}
            errorText={!shouldShowAddressFields ? fieldErrors.addressAutocomplete : undefined}
            onSelect={onAddressSelected}
            placeholder={addressAutocompletePlaceholder}
          />
        </Box>
        {!shouldShowAddressFields && (
          <Box sx={{ paddingBottom: '$2' }}>
            <Link onPress={onPressEnterManually}>{enterAddressManuallyText}</Link>
          </Box>
        )}
        {shouldShowAddressFields && (
          <>
            <TextInput {...streetAddressInputProps} />
            <TextInput {...suiteInputProps} />
            <TextInput {...cityInputProps} />
            <Box sx={{ flexDirection: 'row', gap: '$4', zIndex: 2 }}>
              <Box sx={{ flex: 1 }}>
                <DropdownSmall {...americanStateInputProps} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <TextInput {...zipInputProps} />
              </Box>
            </Box>
          </>
        )}
        {shouldShowOwnershipPercentageField && <TextInput {...ownershipPercentageInputProps} />}
        {shouldShowCheckbox && (
          <Box sx={{ pb: '$4' }}>
            <Checkbox
              testID={`send-${ownerId}-email-invitation-checkbox`}
              isChecked={shouldSendInvite}
              value={'send-additional-owner-email-invitation'}
              labelText={inviteCheckboxLabel}
              onChange={handleCheckboxChange}
            />
          </Box>
        )}
      </Box>

      {shouldShowCancelAndDoneButtons && !areSeparateOwnerProfileFormsEnabled && (
        <ButtonGroup>
          {doneButtonProps && <PrimaryButton {...doneButtonProps} />}
          {cancelButtonProps && <SecondaryButton {...cancelButtonProps} />}
        </ButtonGroup>
      )}
    </Box>
  )
}
