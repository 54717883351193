import { LDProvider, LDSingleKindContext } from 'launchdarkly-react-client-sdk'
import { ReactNode, useEffect } from 'react'
import { useAuth0UserContext } from '@/routes/providers/auth0-user'
import { config, isProduction } from '@/utils/environment'
import { analytics } from '../analytics/events'
import { useAppSelector } from '../redux/utils'

const isEmailRequired = !isProduction()

export const LaunchDarklyWrapper = ({ children }: { children: ReactNode }) => {
  const businessId = useAppSelector((state) => state.application.businessId)
  const userId = useAppSelector((state) => state.application.userId)
  const auth0UserData = useAuth0UserContext()

  const email = auth0UserData?.email
  const isEmailLoaded = isEmailRequired ? Boolean(email) : true

  useEffect(() => {
    if (!userId || !businessId || Boolean(isEmailRequired && !email)) return
    void analytics.featureFlags.identify({ userId, businessId })
  }, [businessId, userId, email])

  if (!userId || !businessId || !isEmailLoaded) return null

  const context: LDSingleKindContext = {
    kind: 'user',
    key: userId,
    businessId,
    email: isEmailRequired ? email : undefined,
  }

  return (
    <LDProvider
      clientSideID={config.launchDarklyClientSideId}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      context={context}
      deferInitialization
    >
      {children}
    </LDProvider>
  )
}
