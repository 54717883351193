import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { CONTROL_PERSON_CANDIDATE_ID, PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import {
  getVariablesForApplicationSubmit,
  getVariablesForApplicationSubmitDataLayerEvent,
} from '@/core/redux/application-redux/selectors'
import { useAppSelector } from '@/core/redux/utils'
import { ACCOUNT_TOP_UPS_ENABLED } from '@/features/features'
import {
  OnboardingAccountStatus,
  useApplicationSubmitAndPreconfigureInvitationsMutation,
  useApplicationSubmitScreenSuspenseQuery,
} from '@/generated/graphql'
import useBusinessType from '@/hooks/useBusinessType'
import { useIsBusinessProfileValid } from '@/routes/business-details/hooks'
import { Pathname } from '@/routes/constants'
import {
  useOwnerProfileListIsValid,
  useOwnerProfileValidation,
} from '@/routes/ownership/owner-profiles/utils/owner-validation-hooks'
import { getN1AnonymousId } from '@/utils/anonymous-id'
import { pushToDataLayer } from '@/utils/data-layer'

export const ApplicationSubmit = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const variables = useAppSelector(getVariablesForApplicationSubmit)
  const applicationSubmitDataLayerVariables = useAppSelector(getVariablesForApplicationSubmitDataLayerEvent)
  const { businessId, owners } = useAppSelector((state) => state.application)
  const [hasError, setHasError] = useState(false)
  const { isSoleProp } = useBusinessType()
  const { valid: areOwnerProfilesValid, checks: ownerProfileListChecks } = useOwnerProfileListIsValid()
  const { hasError: solepropOwnerProfileHasError, checks: solepropOwnerProfileChecks } = useOwnerProfileValidation({
    ownerId: PRIMARY_OWNER_ID,
  })
  const { valid: isBusinessProfileValid, checks: businessProfileValidationChecks } = useIsBusinessProfileValid()
  const isFinancialAdminInvitingEnabled = useFeatureFlag('financial-admin-invites-enabled')
  const n1AnonymousId = getN1AnonymousId()

  const [submitApplicationAndPreconfigureInvitations] = useApplicationSubmitAndPreconfigureInvitationsMutation({
    onError: (e) => {
      // Handle concurrent submits
      if (e.message.includes('409')) {
        navigate(Pathname.USAGE_QUESTIONS, { replace: true })
        return
      }
      analytics.application.submitError({
        error: e.message,
      })
      setHasError(true)
    },
  })

  const { data: submitScreenData } = useApplicationSubmitScreenSuspenseQuery({ variables: { businessId } })

  useEffect(() => {
    if (!variables || !owners || isFinancialAdminInvitingEnabled === undefined) return
    const onboardingData = submitScreenData.business?.onboarding

    if (!onboardingData) {
      setHasError(true)
      return
    }

    // Handle applications who have already submitted their application
    if (onboardingData.application?.status) {
      if (onboardingData.accountStatus === OnboardingAccountStatus.NEEDS_ACCOUNT_USAGE_SURVEY) {
        return navigate(Pathname.USAGE_QUESTIONS, { replace: true })
      }
      return navigate(Pathname.APPLICATION_STATUS, { replace: true })
    }

    if (!isBusinessProfileValid) {
      analytics.application.submitPrevented({
        message: 'Application submit prevented due to invalid business profile',
        checks: businessProfileValidationChecks,
      })
      navigate(Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS, { replace: true })
      return
    }

    if ((isSoleProp && solepropOwnerProfileHasError) || (!isSoleProp && !areOwnerProfilesValid)) {
      analytics.application.submitPrevented({
        message: 'Application submit prevented due to invalid owner profile(s)',
        checks: isSoleProp ? solepropOwnerProfileChecks : ownerProfileListChecks,
      })
      navigate(Pathname.OWNERSHIP_DETAILS_OWNER_PROFILES, { replace: true })
      return
    }

    const performApplicationSubmit = async () => {
      analytics.application.submit({ n1AnonymousId })
      pushToDataLayer({ event: 'Application Submit', n1AnonymousId, ...applicationSubmitDataLayerVariables })
      analytics.application.submitLoadStart()

      // Ensure financial admins aren't invited if the feature flag is off
      if (!isFinancialAdminInvitingEnabled) {
        const financialAdminEmail = owners[CONTROL_PERSON_CANDIDATE_ID]?.email
        if (financialAdminEmail && variables.emails.includes(financialAdminEmail)) {
          variables.emails = Object.values(owners)
            .filter((owner) => owner.isInviteCheckboxSelected)
            .map((owner) => owner.email)
            .filter((email) => email !== financialAdminEmail)
        }
      }

      const { data } = await submitApplicationAndPreconfigureInvitations({
        variables,
      })

      const applicationId = data?.onboardingApplicationSubmit.application.id
      if (!applicationId) {
        setHasError(true)
        return
      }
      dispatch(applicationActions.setApplicationId(applicationId))
      analytics.application.submitLoadComplete()
      // If account top ups are enabled, navigate to account top ups, otherwise skip to usage questions
      ACCOUNT_TOP_UPS_ENABLED
        ? navigate(Pathname.ACCOUNT_TOP_UPS, { replace: true })
        : navigate(Pathname.USAGE_QUESTIONS, { replace: true })
    }
    performApplicationSubmit()
  }, [
    dispatch,
    navigate,
    submitApplicationAndPreconfigureInvitations,
    submitScreenData,
    areOwnerProfilesValid,
    isBusinessProfileValid,
    businessProfileValidationChecks,
    isSoleProp,
    solepropOwnerProfileHasError,
    ownerProfileListChecks,
    solepropOwnerProfileChecks,
    variables,
    isFinancialAdminInvitingEnabled,
    owners,
  ])

  if (hasError) throw new Error('Error submitting application')

  return <LoadingScreen />
}
