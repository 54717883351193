import { analytics as segmentAnalytics } from '@northone/segment-js'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { hasGQLErrorType } from '@/core/apollo/utils'
import { Intercom } from '@/core/intercom'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { transformApplication } from '@/features/partnerships-api/transformers'
import { BaseLayout } from '@/layouts/BaseLayout'
import { Pathname } from '@/routes/constants'
import { navigateToWebBanking } from '@/routes/funding/utils'
import { useAuth0UserContext } from '@/routes/providers/auth0-user'
import { getN1AnonymousId } from '@/utils/anonymous-id'
import {
  ErrorCodeEnum,
  OnboardingAccountStatus,
  usePostAuthWrapperQuery,
  useRedeemReferralOtpMutation,
  useRegisterUserMutation,
} from '../../generated/graphql'
import { getHotjarClient } from '../hotjar'
import { renewTokenOrLogout } from './auth-service'
import { useApplySignupPromoCode } from './use-apply-signup-promo-code'

const useRedeemReferralOTP = () => {
  const dispatch = useDispatch()
  const auth0UserData = useAuth0UserContext()
  const [loading, setLoading] = useState(true)

  const partnerId = auth0UserData?.metadata?.partner_id
  const partnerName = auth0UserData?.metadata?.utm_campaign
  const otp = auth0UserData?.metadata?.papi_otp

  const [redeemReferralOtp] = useRedeemReferralOtpMutation({
    onCompleted: (data) => {
      if (!data.redeemReferralOTP?.application) {
        setLoading(false)
        return
      }
      const referralData = transformApplication(data.redeemReferralOTP.application)
      dispatch(applicationActions.setReferralApplicationData(referralData))
      dispatch(applicationActions.setReferralDataWasHydrated(true))
      analytics.partnershipsAPI.papiReferralDataHydrated({ partnerId, partnerName, otp })
    },
    onError: (error) => {
      analytics.partnershipsAPI.papiReferralDataError({ partnerId, partnerName, otp, errorMessage: error.message })
      setLoading(false)
    },
  })

  useEffect(() => {
    if (!otp) return setLoading(false)

    redeemReferralOtp({
      variables: { token: otp },
    })
  }, [redeemReferralOtp, otp])
  return loading
}

export const PostAuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth0UserData = useAuth0UserContext()
  const [isLoading, setIsLoading] = useState(true)
  const hotjar = getHotjarClient()

  const referralDataLoading = useRedeemReferralOTP()
  const { registerUser, registerUserLoading } = useRegisterUser()
  const n1AnonymousId = getN1AnonymousId()

  useApplySignupPromoCode()

  useEffect(() => {
    const syncRenewTokenOrLogout = () => {
      void renewTokenOrLogout()
    }
    // verifies the session is still active whenever the window is focused on
    window.addEventListener('focus', syncRenewTokenOrLogout)
    return () => window.removeEventListener('focus', syncRenewTokenOrLogout)
  }, [])

  const { refetch } = usePostAuthWrapperQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      // Swallow errors while the user does not have the business:manage scope
      const errorsContain403 = error.graphQLErrors.some((e) => e.message.includes('403'))
      if (errorsContain403) {
        return
      }

      throw new Error('Error in post-auth-wrapper')
    },
    onCompleted: (data) => {
      // Register the user and navigate straight to email verify, bypassing onboarding status wrapper (It's slow)
      if (!data.me) {
        registerUser().then(() => {
          navigate(Pathname.WELCOME_EMAIL_VERIFY)
          setIsLoading(false)
          refetch()
        })
        return
      }

      const isPrimaryOwner = data.me?.isPrimaryOwner
      const isOnboardingComplete = data.me.onboardingCompleted
      const isAccountOpen = data.me?.ownerBusinesses?.[0]?.onboarding?.accountStatus === OnboardingAccountStatus.OPENED
      const businessId = data.me?.ownerBusinesses?.[0]?.id
      const { id: userId, firstName, lastName } = data.me
      const fullName = `${firstName} ${lastName}`.trim()
      const email = data.me.email ?? auth0UserData?.email

      if (!businessId) throw new Error('businessID is required')
      if (!email) throw new Error('Email not found for user but is required')

      // Route additional owners
      if (isPrimaryOwner === false) {
        if (isOnboardingComplete) {
          navigateToWebBanking()
          return
        }
      } else {
        dispatch(applicationActions.updateOwner({ updatedOwnerFields: { email }, ownerId: PRIMARY_OWNER_ID }))

        // Route primary owners with open accounts
        if (isAccountOpen) {
          navigateToWebBanking()
          return
        }
      }

      dispatch(applicationActions.setUserId(userId))
      dispatch(applicationActions.setBusinessId(businessId))

      Intercom.boot({ email, name: fullName, user_id: userId })
      hotjar.identify(userId, { businessId })
      segmentAnalytics().identify(userId, {
        email,
        businessId,
        n1AnonymousId,
      })
      Sentry.setUser({
        id: userId,
        email,
        username: fullName,
      })

      setIsLoading(false)
    },
  })

  return isLoading || referralDataLoading || registerUserLoading ? (
    <BaseLayout>
      <LoadingScreen />
    </BaseLayout>
  ) : (
    <>{children}</>
  )
}

const useRegisterUser = () => {
  const dispatch = useDispatch()
  const auth0UserData = useAuth0UserContext()
  const [registerUserLoading, setRegisterUserIsLoading] = useState(false)
  const mobilePhone = auth0UserData?.metadata?.mobile_phone
  const email = auth0UserData?.email
  const hotjar = getHotjarClient()

  // We can call this mutation multiple times and a user will only be registered once
  const [registerUserMutation] = useRegisterUserMutation({
    variables: { phone: mobilePhone },
    onCompleted: (data) => {
      if (!data.registerUser?.success) return

      const phone = data.registerUser.user?.phone
      const businessId = data.registerUser.user?.businessId ?? ''
      const userId = data.registerUser.user?.id ?? ''

      businessId && dispatch(applicationActions.setBusinessId(businessId))
      dispatch(applicationActions.setUserId(userId))
      if (email) {
        dispatch(
          applicationActions.updateOwner({
            updatedOwnerFields: { email },
            ownerId: PRIMARY_OWNER_ID,
          }),
        )
      }

      if (phone) {
        dispatch(
          applicationActions.updateOwner({
            updatedOwnerFields: { phoneNumber: phone },
            ownerId: PRIMARY_OWNER_ID,
          }),
        )
      }

      hotjar.identify(userId, { businessId })
      segmentAnalytics().alias(userId, () => {
        segmentAnalytics().identify(
          userId,
          {
            email,
            phone,
            businessId,
            partnerId: auth0UserData?.metadata?.partner_id,
            partnerName: auth0UserData?.metadata?.utm_campaign,
          },
          {
            campaign: {
              name: auth0UserData?.metadata?.utm_campaign,
              source: auth0UserData?.metadata?.utm_source,
              term: auth0UserData?.metadata?.utm_term,
              medium: auth0UserData?.metadata?.utm_medium,
              content: auth0UserData?.metadata?.utm_content,
              creative: auth0UserData?.metadata?.utm_creative,
            },
          },
        )
      })
    },
    onError: (error) => {
      if (hasGQLErrorType(error, ErrorCodeEnum.AUTH_USER_ALREADY_EXISTS)) {
        setRegisterUserIsLoading(false)
        return
      }
      throw new Error('Error registering user')
    },
  })

  return {
    registerUser: async () => {
      setRegisterUserIsLoading(true)
      await registerUserMutation()
        .catch((err) => {
          throw err
        })
        .finally(() => {
          setRegisterUserIsLoading(false)
        })
    },
    registerUserLoading,
  }
}
