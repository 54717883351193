import { analytics as segmentAnalytics } from '@northone/segment-js'
import {
  BackArrowIcon,
  Box,
  FaviconIcon,
  HorizontalProgressBar,
  ICONS,
  MobileHeaderBar,
  ProgressNote,
  SizeEnum,
  Stepper,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { ReactNode, Suspense, useEffect } from 'react'
import { Pressable } from 'react-native'
import { matchPath, useLocation } from 'react-router-dom'
import { AppMenu } from '@/components/DropdownMenu'
import Loader from '@/components/Loader'
import LoadingScreen from '@/components/LoadingScreen'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { useAppSelector } from '@/core/redux/utils'
import { ACCOUNT_TOP_UPS_ENABLED } from '@/features/features'
import { Pathname } from '@/routes/constants'
import { BaseLayout } from './BaseLayout'
import { getPathnameWithoutTrailingSlash } from './getPathnameWithoutTrailingSlash'

type StepperSteps = React.ComponentProps<typeof Stepper>['steps']

interface StepperLayoutProps {
  children: ReactNode
  isUserPrimaryOwner: boolean
  activeStepIndex?: number
}

export interface OnboardingStepperProps {
  activeStepIndex: number
  steps: StepperSteps
}

const getOnboardingSteps = ({
  topUpsEnabled,
  initialFundingEnabled,
}: {
  topUpsEnabled: boolean
  initialFundingEnabled: boolean
}): StepperSteps => {
  return [
    { text: 'Welcome' },
    { text: 'Business details' },
    { text: 'Ownership details' },
    ...(initialFundingEnabled ? [{ text: 'Account funding' }] : []),
    ...(topUpsEnabled ? [{ text: 'Account top-ups' }] : []),
    { text: 'Account usage' },
    { text: 'Finish up' },
  ]
}

const additionalOwnerSteps: StepperSteps = [
  { text: 'Debit card' },
  { text: 'Terms and conditions' },
  { text: 'Finish up' },
]

const getPathnameFromDynamicPath = (currentPath: string) => {
  const dynamicPathnames = [Pathname.OWNERSHIP_DETAILS_ADD_OWNER, Pathname.OWNERSHIP_DETAILS_EDIT_OWNER]
  for (const dynamicPathname of dynamicPathnames) {
    const isMatch = matchPath({ path: dynamicPathname }, currentPath)
    if (isMatch) return dynamicPathname
  }
  return null
}

const additionalOwnerStepIndexMap = new Map<Pathname | string, number>([
  [Pathname.JOIN_TEAM, 0],
  [Pathname.JOIN_TEAM_SHIPPING_ADDRESS, 0],
  [Pathname.JOIN_TEAM_TERMS_AND_CONDITIONS, 1],
  [Pathname.JOIN_TEAM_CELEBRATION, 2],
])

const welcomePagesIndexMap = new Map<Pathname | string, number>([
  [Pathname.WELCOME_GETTING_STARTED, 0],
  [Pathname.WELCOME_EMAIL_VERIFY, 0],
  [Pathname.WELCOME_BUSINESS_TYPE, 0],
  [Pathname.WELCOME_QUALIFIERS, 0],
  [Pathname.WELCOME_TERMS_AND_CONDITIONS, 0],
])

const businessPagesIndexMap = new Map<Pathname | string, number>([
  [Pathname.BUSINESS_DETAILS, 1],
  [Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS, 1],
  [Pathname.BUSINESS_DETAILS_DESCRIPTION_AND_INDUSTRY, 1],
  [Pathname.BUSINESS_DETAILS_CONTACT_INFO, 1],
  [Pathname.BUSINESS_DETAILS_EXPECTED_REVENUE, 1],
  [Pathname.BUSINESS_DETAILS_ONLINE_PRESENCE, 1],
])

const ownershipPagesIndexMap = new Map<Pathname | string, number>([
  [Pathname.OWNERSHIP_DETAILS, 2],
  [Pathname.OWNERSHIP_DETAILS_OWNER_PROFILES, 2],
  [Pathname.OWNERSHIP_DETAILS_CONTROLLER, 2],
  [Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER, 2],
  [Pathname.OWNERSHIP_DETAILS_ADD_OWNER, 2],
  [Pathname.OWNERSHIP_DETAILS_EDIT_OWNER, 2],
  [Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS, 2],
])

const stepIndexMapWithTopUps = new Map<Pathname | string, number>([
  ...welcomePagesIndexMap,
  ...businessPagesIndexMap,
  ...ownershipPagesIndexMap,

  [Pathname.ACCOUNT_FUNDING, 3],
  [Pathname.ACCOUNT_FUNDING_LINK_EXTERNAL_ACCOUNT, 3],
  [Pathname.ACCOUNT_FUNDING_ACCOUNT_TYPE, 3],
  [Pathname.ACCOUNT_FUNDING_AMOUNT, 3],
  [Pathname.APPLICATION_SUBMIT, 3],

  [Pathname.ACCOUNT_TOP_UPS, 4],
  [Pathname.ACCOUNT_TOP_UPS_SETUP, 4],

  [Pathname.USAGE_QUESTIONS, 5],
  [Pathname.USAGE_QUESTION_DEBIT_CARD_AMOUNT, 5],
  [Pathname.USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT, 5],
  [Pathname.USAGE_QUESTION_CHECK_DEPOSITS, 5],
  [Pathname.USAGE_QUESTION_ACH_PAYMENTS, 5],
  [Pathname.USAGE_QUESTION_WIRE_PAYMENTS, 5],

  [Pathname.DOCUMENT_UPLOAD, 6],
  [Pathname.DOCUMENT_UPLOAD_FORM, 6],
  [Pathname.DOCUMENT_UPLOAD_THANK_YOU, 6],

  [Pathname.FINISH_UP, 7],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT, 7],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK, 7],
  [Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE, 7],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP, 7],
  [Pathname.FINISH_UP_FILE_UPLOAD_THANK_YOU, 7],
  [Pathname.APPLICATION_STATUS, 7],
  [Pathname.DEBIT_CARD_DELIVERY_ADDRESS, 7],
  [Pathname.FINISH_UP_WELCOME_SCREEN, 7],

  // Join Team/additional owner pages
  ...additionalOwnerStepIndexMap,
])

const stepIndexMapWithoutTopUps = new Map<Pathname | string, number>([
  ...welcomePagesIndexMap,
  ...businessPagesIndexMap,
  ...ownershipPagesIndexMap,

  [Pathname.ACCOUNT_FUNDING, 3],
  [Pathname.ACCOUNT_FUNDING_LINK_EXTERNAL_ACCOUNT, 3],
  [Pathname.ACCOUNT_FUNDING_ACCOUNT_TYPE, 3],
  [Pathname.ACCOUNT_FUNDING_AMOUNT, 3],
  [Pathname.APPLICATION_SUBMIT, 3],

  [Pathname.USAGE_QUESTIONS, 4],
  [Pathname.USAGE_QUESTION_DEBIT_CARD_AMOUNT, 4],
  [Pathname.USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT, 4],
  [Pathname.USAGE_QUESTION_CHECK_DEPOSITS, 4],
  [Pathname.USAGE_QUESTION_ACH_PAYMENTS, 4],
  [Pathname.USAGE_QUESTION_WIRE_PAYMENTS, 4],

  [Pathname.DOCUMENT_UPLOAD, 5],
  [Pathname.DOCUMENT_UPLOAD_FORM, 5],
  [Pathname.DOCUMENT_UPLOAD_THANK_YOU, 5],

  [Pathname.FINISH_UP, 6],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT, 6],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK, 6],
  [Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE, 6],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP, 6],
  [Pathname.FINISH_UP_FILE_UPLOAD_THANK_YOU, 6],
  [Pathname.APPLICATION_STATUS, 6],
  [Pathname.DEBIT_CARD_DELIVERY_ADDRESS, 6],
  [Pathname.FINISH_UP_WELCOME_SCREEN, 6],

  // Join Team/additional owner pages
  ...additionalOwnerStepIndexMap,
])

const stepIndexMapWithoutPlaid = new Map<Pathname | string, number>([
  ...welcomePagesIndexMap,
  ...businessPagesIndexMap,
  ...ownershipPagesIndexMap,

  [Pathname.APPLICATION_SUBMIT, 2],

  [Pathname.USAGE_QUESTIONS, 3],
  [Pathname.USAGE_QUESTION_DEBIT_CARD_AMOUNT, 3],
  [Pathname.USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT, 3],
  [Pathname.USAGE_QUESTION_CHECK_DEPOSITS, 3],
  [Pathname.USAGE_QUESTION_ACH_PAYMENTS, 3],
  [Pathname.USAGE_QUESTION_WIRE_PAYMENTS, 3],

  [Pathname.DOCUMENT_UPLOAD, 4],
  [Pathname.DOCUMENT_UPLOAD_FORM, 4],
  [Pathname.DOCUMENT_UPLOAD_THANK_YOU, 4],

  [Pathname.FINISH_UP, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_THANK_YOU, 5],
  [Pathname.APPLICATION_STATUS, 5],
  [Pathname.DEBIT_CARD_DELIVERY_ADDRESS, 5],
  [Pathname.FINISH_UP_WELCOME_SCREEN, 5],

  // Join Team/additional owner pages
  ...additionalOwnerStepIndexMap,
])

const pathsWithFaviconInHeader = [
  // The temporary nature of the email verification screen makes back-navigation tricky
  Pathname.WELCOME_GETTING_STARTED,
  Pathname.WELCOME_EMAIL_VERIFY,
  Pathname.WELCOME_BUSINESS_TYPE,

  // Expectation setting pages
  Pathname.BUSINESS_DETAILS,
  Pathname.OWNERSHIP_DETAILS,
  Pathname.ACCOUNT_FUNDING,
  Pathname.ACCOUNT_TOP_UPS,
  Pathname.USAGE_QUESTIONS,
  Pathname.FINISH_UP,

  // Exit pages
  Pathname.DOCUMENT_UPLOAD_THANK_YOU,
  Pathname.REJECTED,
  Pathname.FINISH_UP_WELCOME_SCREEN,

  // Epiphemeral pages
  Pathname.OAUTH,
  Pathname.APPLICATION_STATUS,

  // Invited owner entry/exit pages
  Pathname.JOIN_TEAM_SHIPPING_ADDRESS,
  Pathname.JOIN_TEAM_CELEBRATION,
]

const OnboardingStepperWeb = ({ activeStepIndex, steps }: OnboardingStepperProps) => (
  <Box
    sx={{
      backgroundColor: '$white1',
      width: '$80',
      paddingVertical: '$16',
      paddingHorizontal: '$12',
      borderRightWidth: 1,
      borderColor: '$charcoal1',
      display: 'flex',
    }}
  >
    <div style={{ position: 'sticky', top: 50, display: 'flex', flexDirection: 'column', gap: 32 }}>
      <Stepper steps={steps} activeStepIndex={activeStepIndex} numbered />
      <ProgressNote note="Your progress is auto-saved" icon={ICONS.BookmarkIcon} />
    </div>
  </Box>
)

export const OnboardingHeaderMobile = (props: { stepperProps?: OnboardingStepperProps } | undefined) => {
  const pathname = window.location.pathname
  const dynamicPathname = getPathnameFromDynamicPath(pathname)
  const pathnameWithoutTrailingSlash = dynamicPathname ?? (getPathnameWithoutTrailingSlash(pathname) as Pathname)
  const goBack = () => window.history.back()

  const currentStepText = props?.stepperProps?.activeStepIndex
    ? props.stepperProps.steps[props.stepperProps.activeStepIndex]?.text ?? ''
    : ''
  const showHeaderFavicon = pathsWithFaviconInHeader.includes(pathnameWithoutTrailingSlash)

  return (
    <Box>
      <Box sx={{ zIndex: 2 }}>
        <MobileHeaderBar
          title={currentStepText}
          leftAction={
            showHeaderFavicon ? (
              <FaviconIcon size={SizeEnum.MD} />
            ) : (
              <Pressable onPress={goBack}>
                <BackArrowIcon size={SizeEnum.MD} />
              </Pressable>
            )
          }
          rightAction={<AppMenu />}
        />
      </Box>
      {props?.stepperProps?.steps.length && (
        <HorizontalProgressBar
          totalStepCount={props.stepperProps.steps.length}
          activeStepIndex={props.stepperProps.activeStepIndex}
        />
      )}
    </Box>
  )
}

export const StepperScreen = ({ children, isUserPrimaryOwner }: StepperLayoutProps) => {
  const { pathname } = useLocation()
  const { isMobileSize } = useMobileScreenSize()
  const isPlaidEnabled = useFeatureFlag('joindot-initial-funding')
  const userId = useAppSelector((state) => state.application.userId)

  useEffect(() => {
    if (!userId || isPlaidEnabled === undefined) return
    segmentAnalytics().identify(userId, {
      onboardingInitialFundingRequired: isPlaidEnabled,
    })
  }, [isPlaidEnabled, userId])

  const dynamicPathname = getPathnameFromDynamicPath(pathname)
  const pathnameWithoutTrailingSlash = dynamicPathname ?? getPathnameWithoutTrailingSlash(pathname)
  const stepIndexes =
    isPlaidEnabled === false
      ? stepIndexMapWithoutPlaid
      : ACCOUNT_TOP_UPS_ENABLED
      ? stepIndexMapWithTopUps
      : stepIndexMapWithoutTopUps
  const activeStepIndex = stepIndexes.get(pathnameWithoutTrailingSlash)
  const containerFlexDirection = isMobileSize ? 'column' : 'row'

  const steps = isUserPrimaryOwner
    ? getOnboardingSteps({ topUpsEnabled: ACCOUNT_TOP_UPS_ENABLED, initialFundingEnabled: isPlaidEnabled })
    : additionalOwnerSteps

  const stepperProps = { steps, activeStepIndex: activeStepIndex ?? 0 }

  if (isPlaidEnabled === undefined) return <LoadingScreen />

  return (
    <BaseLayout stepperProps={stepperProps}>
      <Box sx={{ flexDirection: containerFlexDirection, flex: 1 }}>
        {!isMobileSize ? <OnboardingStepperWeb {...stepperProps} /> : null}
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </Box>
    </BaseLayout>
  )
}
